import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/shared/services/db/user.service';
import { EnrollmentServiceService } from 'src/app/shared/services/db/enrollment-service.service';

@Component({
  selector: 'app-agreement-report',
  templateUrl: './agreement-report.component.html',
  styleUrls: ['./agreement-report.component.css']
})
export class AgreementReportComponent implements OnInit {
	private userId: string;
	private agreementChecked: boolean = false;
  private fullName: string = '';

	constructor(
		private userService: UserService,
    private enrollmentService: EnrollmentServiceService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.userId = this.route.snapshot.params.student;
    this.enrollmentService.setUserId(this.userId);
		this.getUserData();
    this.getFullName();
	}

	private getUserData(){
		this.userService.getUserData(this.userId).then((snap) => {
			if (snap.data()){
				this.agreementChecked = snap.data().agreementCompleteness;
			}
		}).catch((error) => {
			console.error("Error trying get user data", error);
		})
	}

  private getFullName():void {
    this.enrollmentService.getFullName().then(snap => {
      this.fullName = snap;
    });
  }
}
