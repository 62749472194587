export const PsicoQuestions = [
	"Siento que mi padre raras veces me",
	"Cuando tengo mala suerte",
	"Siempre anhelé ",
	"Si yo estuviera a cargo ",
	"El futuro me parece",
	"Las personas que están sobre mi",
	"Sé que es tonto pero tengo miedo de",
	"Creo que un verdadero amigo",
	"Cuando era niño (a)",
	"Mi idea de hombre (mujer) perfecto (a)",
	"Cuando veo a un hombre y a una mujer juntos",
	"Comparando las demás familias, la mía ",
	"En las labores me llevo mejor con",
	"Mi madre",
	"Haría cualquier cosa por olvidar la vez que",
	"Si mi padre tan solo",
	"Siento que tengo habilidades para",
	"Sería perfectamente feliz si",
	"Si la gente trabajara para mi",
	"Yo espero",
	"En la escuela, mis maestros",
	"La mayoría de mis amistades no saben que tengo miedo de",
	"No me gusta",
	"Antes",
	"Pienso que la mayoría de los muchachos (a)",
	"Yo creo que la vida matrimonial",
	"Mi familia me trata como",
	"Aquellos con los que trabajo",
	"Mi madre y yo",
	"Mi más grande error fue",
	"Desearía que mi padre",
	"Mi mayor debilidad",
	"Mi ambición secreta en la vida",
	"La gente que trabaja para mi",
	"Algún día yo",
	"Cuando veo al jefe venir",
	"Quisiera perder el miedo de",
	"La gente que más me agrada ",
	"Si fuera joven otra vez",
	"Creo que la mayoría de las mujeres (hombres)",
	"Si tuviera relaciones sexuales",
	"La mayoría de las familias que conozco",
	"Me gusta trabajar con la gente que",
	"Creo que la mayoría de las madres",
	"Cuando era más joven me sentía culpable de",
	"Siento que mi padre es",
	"Cuando la suerte se vuelve en contra mía",
	"Cuando doy ordenes, yo",
	"Lo que más deseo en la vida es",
	"Dentro de algún tiempo",
	"La gente a quien yo considero mis superiores",
	"Mis temores en ocasiones me obligan a",
	"Cuando no estoy, mis amigos",
	"Mi más vívido recuerdo de la infancia",
	"Lo que menos me gusta de las mujeres (hombres) ",
	"Mi vida sexual",
	"Cuando era niño (a)",
	"La gente que trabaja conmigo, generalmente",
	"Me agrada mi madre, pero",
	"La peor cosa que he hecho"
]