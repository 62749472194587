import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UserService } from 'src/app/shared/services/db/user.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseStorageService } from 'src/app/shared/services/storage/firebase-storage.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})

export class AttachmentsComponent implements OnInit {
	private userId: string;
	private studentId: string;
	public mensajeArchivo = 'No hay un archivo seleccionado';
	public datosFormulario = new FormData();
	public fileName = '';
	public URLPublica = '';
	public porcentaje = 0;
	public finalizado = false;
	public filesList = {};
	public fullFileNames = {};
	public studentName: string;
	public completeness: number = 0;
	public breakPoints = 1;
	objectKeys = Object.keys;

	iconTitle:string="<mat-icon>notes</mat-icon>";
	@Output() navigationEmitter = new EventEmitter<string>();

	constructor(public authService: AuthService, private firebaseStorage: FirebaseStorageService, private route: ActivatedRoute, private userService: UserService, private _snackBar: MatSnackBar) { }

	ngOnInit(): void {
		this.userId = this.authService.userData.uid;
		this.studentId = this.route.snapshot.params.student;
		this.breakPoints = window.innerWidth <= 600 ? 1 : 4;

		if (this.studentId != null && this.studentId != undefined){
			console.log('Setting studentid', this.studentId);
			this.firebaseStorage.setUserId(this.studentId);
			
			this.userService.getUserData(this.studentId).then((snap) => {
				if (snap.data()){
					this.studentName = snap.data().displayName;
				}
			}).catch((error) => {
				console.error("Error trying get user data", error);
			})
		}else{
			console.log('Setting userId', this.userId);
			this.firebaseStorage.setUserId(this.userId);
		}

		this.initializeData();
		this.getDataFromDB('curp');
		this.getDataFromDB('acta');
		this.getDataFromDB('studiesdoc');
		this.getDataFromDB('ine');
		this.getDataFromDB('recomendation1');
		this.getDataFromDB('recomendation2');
		this.getDataFromDB('recomendation3');
	}

	private initializeData(){
		this.fullFileNames['curp'] = {'filename'	: 'CURP'};
		this.fullFileNames['acta']= {'filename' : 'Acta de Nacimiento'};
		this.fullFileNames['studiesdoc'] = {'filename': 'Comprobante de estudios'};
		this.fullFileNames['ine']={'filename' : 'INE'};
		this.fullFileNames['recomendation1']={'filename': 'Carta de recomendación 1'};
		this.fullFileNames['recomendation2']={'filename': 'Carta de recomendación 2'};
		this.fullFileNames['recomendation3']={'filename': 'Carta de recomentación 3'};
	}

	private getDataFromDB(index: string){
		this.completeness = 0;
		this.filesList[index] = {
			publicUrl: '',
			percentage: 0,
			filename:index,
			finalized: false
		};

		//this.authService.setFormCompletude(this.authService.userData.uid, {"attachmentsCompleteness": this.completeness});

		let storedFilename = index + '.pdf';
		let referencia = this.firebaseStorage.referenciaCloudStorage(storedFilename);
		referencia.getDownloadURL().subscribe((URL) => {
			if(URL != null && URL != undefined){
				this.filesList[index].publicUrl = URL;
				this.filesList[index].percentage = 100;
				this.filesList[index].finalized = true;
				this.completeness += (1 / Object.keys(this.fullFileNames).length)*100;
				this.authService.setFormCompletude(this.authService.userData.uid, {"attachmentsCompleteness": this.completeness});
			}
		});
	}

	public dragOverFunction(event){
		event.preventDefault();
	}

	public onFileDragged(event, filename){
		event.stopPropagation();
		event.preventDefault();

		let file = event.dataTransfer.files[0];
		let validExtensions = ["application/pdf"];
		let fileType = file.type;
		if (validExtensions.includes(fileType)){
			this.uploadFile(file, filename);
		}else{
			this._snackBar.open('Sólo se permiten archivos pdf', 'Ok', {
			duration: 3000
		  });
			console.log('Not valid file type, just pdfs');
		}
	}
	
	public onFileSelected(event, filename){
		const file:File = event.target.files[0];
		if (file){
			this.uploadFile(file,filename);
		}
	}
	
	  //Sube el archivo a Cloud Storage
	private uploadFile(blobFile, filename) {
		if (blobFile.size > 2097152){
			this._snackBar.open('Los archivos no pueden ser mayores a 2MB', 'Ok', {
				duration: 3000
			  });
			console.log('Too big file');
			return;
		}
		let storedFilename = filename + '.pdf';
		let formData = new FormData();
		formData.append('archivo', blobFile);
		let archivo = formData.get('archivo');
		let referencia = this.firebaseStorage.referenciaCloudStorage(storedFilename);
		let tarea = this.firebaseStorage.tareaCloudStorage(storedFilename, archivo);

		this.filesList[filename].publicUrl = null;
		this.filesList[filename].percentage = 0;
		this.filesList[filename].finalized = false;

		if (this.filesList[filename].percentage == 100){
			this.completeness -= (1 / Object.keys(this.fullFileNames).length)*100;
			this.authService.setFormCompletude(this.authService.userData.uid, {"attachmentsCompleteness": this.completeness});
		}

		tarea.percentageChanges().subscribe((porcentaje) => {
			if (Math.round(porcentaje) == 100 && this.filesList[filename].percentage != 100) {
				this.completeness += (1 / Object.keys(this.fullFileNames).length)*100;
				if (this.studentId){
					this.authService.setFormCompletude(this.studentId, {"attachmentsCompleteness": this.completeness});
				}else{
					this.authService.setFormCompletude(this.userId, {"attachmentsCompleteness": this.completeness});
				}
				
			}

			this.filesList[filename].percentage = Math.round(porcentaje);
			if (this.filesList[filename].percentage == 100) {
				this.filesList[filename].finalized = true;
				referencia.getDownloadURL().subscribe((URL) => {
					this.filesList[filename].publicUrl = URL;
				});
			}
		});
	}
	
	private deleteFile(filename: string){
		if (confirm('Estás seguro que deseas eliminar el archivo para '+ this.fullFileNames[filename].filename)) {
			let referencia = this.firebaseStorage.deleteFileFromUrl(this.filesList[filename].publicUrl);

			referencia.subscribe((res) => {		
				this.completeness -= (1 / Object.keys(this.fullFileNames).length) * 100;
				if (this.studentId){
					this.authService.setFormCompletude(this.studentId, {"attachmentsCompleteness": this.completeness});
				}else{
					this.authService.setFormCompletude(this.userId, {"attachmentsCompleteness": this.completeness});
				}

				this.filesList[filename] = {
					publicUrl: '',
					percentage: 0,
					filename:filename,
					finalized: false
				};
			});
		} else {
			console.log('Not delete file for '+filename);
		}
	}

	emmitNavigationClick(componentName: string){
		this.navigationEmitter.emit(componentName);
	}
}
