// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
	apiKey: "AIzaSyDEIgTtIGQyRoHyXKuEtHWbE8wvZaNQzHc",
	authDomain: "tsn-sms.firebaseapp.com",
	databaseURL: "https://tsn-sms.firebaseio.com",
	projectId: "tsn-sms",
	storageBucket: "tsn-sms.appspot.com",
	messagingSenderId: "576841405547",
	appId: "1:576841405547:web:cc57d08a7c54894ab10d2e",
	measurementId: "G-6QZM3M1GG8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
