import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {
	private userId: string;
	constructor(
		private storage: AngularFireStorage
	) { }

	setUserId(userId: string): void{
		this.userId = userId;
	}

	public tareaCloudStorage(filename: string, datos: any) {	  
		let storedFilename = this.userId +'/'+  filename;
		return this.storage.upload(storedFilename, datos);
	}

	public referenciaCloudStorage(filename: string) {
		let storedFilename = this.userId +'/'+  filename;
		return this.storage.ref(storedFilename);
	}

	public deleteFileFromUrl(url: string){
		return this.storage.refFromURL(url).delete();
	}
}