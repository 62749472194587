import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { UserService } from 'src/app/shared/services/db/user.service';
import { Router } from '@angular/router'

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements AfterViewInit {
	private userId: string;

	constructor(public authService: AuthService, private userService: UserService, public router: Router,) {

	}
	ngOnInit(): void {
		this.userId = this.authService.userData.uid;
		if (this.userId != null && this.userId != undefined){
			this.userService.getUserData(this.userId).then((snap) => {
				if (snap.data()?.role != 'admin'){
					this.router.navigate(['dashboard']);
				}
			}).catch((error) => {
				this.router.navigate(['dashboard']);
				console.error("Error trying get user data", error);
			})
		}
	}
	ngAfterViewInit(): void {
	}

	logout(): void {
		this.authService.signOut();
	}

}
