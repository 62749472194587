import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { StudentsDataService } from 'src/app/shared/services/db/students-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSortable} from '@angular/material/sort';

import { Observable } from 'rxjs';
import { element } from 'protractor';
import { start } from 'repl';

export interface StudentsColumns{
	displayName:string,
	email:string,
	creationDate:Date,
	creationLocaleDate:string,
	emailVerified:string,
	enrollmentCompleteness:number,
	personalTestCompleteness:number,
	agreementCompleteness:string,
	completeness:number
}

const VERIFIED_EMAIL = 'Verificado';
const NON_VERIFIED_EMAIL = 'Pendiente de verificar';
const ITEMS_PER_PAGE_LABEL = 'Estudiantes a mostrar';

 
@Component({
	selector: 'app-students-list',
	templateUrl: './students-list.component.html',
	styleUrls: ['./students-list.component.css'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class StudentsListComponent implements AfterViewInit {	
	private columnsNames: string[] = ['displayName', 'email', 'creationLocaleDate', 'emailVerified', 'enrollmentCompleteness', 'personalTestCompleteness','attachmentsCompleteness', 'agreementCompleteness', 'completeness'];
	private dataSource: MatTableDataSource<StudentsColumns>;
	private bkDataSource: MatTableDataSource<StudentsColumns>;
	private isLoadingResults = true;

	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;

	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		//this.setDataSourceAttributes();
	}		

	constructor(private studentsService:StudentsDataService) { }

	setDataSourceAttributes() {
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {
			  case 'creationLocaleDate': 
			  	return new Date(item.creationDate);
			  default: return item[property];
			}
		  };

		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

		/*
		this.bkDataSource.sortingDataAccessor = (item, property) => {
			switch (property) {
			  case 'creationLocaleDate': 
			  	return new Date(item.creationDate);
			  default: return item[property];
			}
		  };

		this.bkDataSource.paginator = this.paginator;
		this.bkDataSource.sort = this.sort;
		*/
	}

	ngAfterViewInit() {
		this.listenStudentsData().subscribe(()=>{
			//if (this.dataSource.paginator == null || this.dataSource.paginator == undefined){
				this.setSortAndPaginateAttributes();
			//}
		});
	}

	setSortAndPaginateAttributes(){
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		this.dataSource.paginator._intl.itemsPerPageLabel= ITEMS_PER_PAGE_LABEL;
		this.dataSource.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => { if (length == 0 || pageSize == 0) { return `0 de ${length}`; } length = Math.max(length, 0); const startIndex = page * pageSize; const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} – ${endIndex} de ${length}`; }
		this.sortDataSource('creationLocaleDate', 'desc');
	}

	listenStudentsData():Observable<any>{
		return new Observable((observer) => {
			this.studentsService.listenUserData().subscribe((studentsData) => {
				let students = [];
				studentsData.filter((element) => element['role'] == undefined ||  element.role != "admin").forEach((element) => {
					let progress = 0;
					//let element: StudentsColumn;

					if (element.uid !== null && element.uid !== undefined && element.uid !== '') {
						element.emailVerified = element['emailVerified'] != undefined && element.emailVerified ? VERIFIED_EMAIL : NON_VERIFIED_EMAIL;
						progress += element['agreementCompleteness'] != undefined && element['agreementCompleteness'] == true ? 100 : 0;
						progress += element['enrollmentCompleteness'] != undefined ? element.enrollmentCompleteness : 0;
						progress += element['personalTestCompleteness'] != undefined ? element.personalTestCompleteness : 0;
						progress += element['attachmentsCompleteness'] != undefined ? element.attachmentsCompleteness : 0;
						element.completeness = parseFloat((progress / 4).toFixed(2));
		
						if (element['creationDate'] != undefined && element['creationDate'] != null){
							let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
							let creationDate = element['creationDate'].toDate();
							let _creationDate = creationDate.toLocaleDateString('es-MX', options).toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());;
							element.creationLocaleDate = _creationDate;
							element.creationDate = element['creationDate'].toDate(); 
						}
						element.enrollmentCompleteness = element['enrollmentCompleteness'] != undefined ? parseFloat(element.enrollmentCompleteness.toFixed(1)) : 0;
						element.personalTestCompleteness = element['personalTestCompleteness'] != undefined ? parseFloat(element.personalTestCompleteness.toFixed(1)) : 0;
						element.attachmentsCompleteness = element['attachmentsCompleteness'] != undefined ? parseFloat(element.attachmentsCompleteness.toFixed(1)) : 0;
		
						element.agreementCompleteness = element['agreementCompleteness'] != undefined && element.agreementCompleteness ? 'Sí' : 'No';
						if (element.displayName != null && element.displayName != undefined)
							element.displayName = element.displayName.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
						
						students.push(element);
					}
				})
	
				this.dataSource = new MatTableDataSource(students);
				this.bkDataSource = new MatTableDataSource(students);
				this.isLoadingResults = false;
				this.setDataSourceAttributes();
				observer.next();
				observer.complete();
			});
		});
	}

	private sortDataSource(id: string, start: string) {
		this.dataSource.sort.sort(<MatSortable>({ id: id, start: start }));
		this.dataSource.data.sort((a: any, b: any) => {
			let a1 = new Date(a.creationDate);
			let b1 = new Date(b.creationDate);
			if (a1 < b1) {
				return -1;
			} else if (a1 > b1) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	private applyFilter(nameFilter: string, emailFilter: string, startDateFilter: string, endDateFilter: string) {
		let dtStartDateFilter = startDateFilter != null && startDateFilter != undefined && startDateFilter != '' ? new Date(startDateFilter) : null;
		let dtEndDateFilter = endDateFilter != null && endDateFilter != undefined && endDateFilter != '' ? new Date(endDateFilter): null;
		nameFilter = nameFilter.trim();
		nameFilter = nameFilter.toLowerCase();
		emailFilter = emailFilter.trim();
		emailFilter = emailFilter.toLowerCase();

		this.dataSource = new MatTableDataSource (this.bkDataSource.data);

		this.dataSource.data = this.dataSource.data.filter(function (e){
			let response = true;
			let displayName = (e.displayName + '').toLowerCase();
			let email = (e.email + '').toLowerCase();

			if (nameFilter && !displayName.includes(nameFilter))		{
				response = false;
			}
			if (startDateFilter && e.creationDate < dtStartDateFilter)	{
				response = false;
			}
			if (endDateFilter && e.creationDate > dtEndDateFilter)		{
				response = false;
			}
			if (emailFilter && !email.includes(emailFilter))			{
				response = false;
			}
			return response;
		});

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}

		this.setDataSourceAttributes();
		this.setSortAndPaginateAttributes();
	}
}
